import { firestore } from "../firebase";
import { doc, setDoc, deleteDoc } from "firebase/firestore";

const f_publicacoes = {};

f_publicacoes.update = (doc_id, pub_data) => {
    const docRef = doc(firestore, "publicacoes", doc_id);
    return setDoc(docRef, pub_data, { merge: true }).then(() => {
        return true;
    }).catch(() => {
        return false;
    });
}

f_publicacoes.delete = (doc_id) => {
    const docRef = doc(firestore, "publicacoes", doc_id);
    return deleteDoc(docRef).then(() => {
        return true;
    }).catch(() => {
        return false;
    });
}

export default f_publicacoes;